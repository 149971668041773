import'./Novedades.scss'

const LibrosIngles = () => {
  return (
    <div className="libros-ingles">
      <img src="assets/libroingles1.jpg" alt="imagen" />
      <img src="assets/libroingles2.jpg" alt="imagen" />
    </div>
  )
}

export default LibrosIngles