import'./Novedades.scss'

const LibrosAula = () => {
  return (
    <div className="libros-aula">
        <img src="assets/libros3.jpg" alt="imagen" />
        <img src="assets/libros2.jpg" alt="imagen" />
        <img src="assets/libros1.jpg" alt="imagen" />
    </div>
  )
}

export default LibrosAula